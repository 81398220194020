<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>
                {{statusCmd[statusCmds[0]]?.label  | translate }}
                <div class="page-title-subheading">{{'data.customer_pickup_list' | translate }}
                    <div class="badge badge-{{statusCmd[statusCmds[0]]?.color}}">
                        {{statusCmd[statusCmds[0]]?.label  | translate }}</div>
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true"
                    class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div *ngIf="isLoading; else elseAllCmds">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllCmds>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">{{'data.pickups' | translate }}
                    <div class="btn-actions-pane-right">
                        <!-- <div role="group" class="btn-group-sm btn-group">
                        <div class="badge badge-warning">{{statusCmds}}</div>
                    </div> -->
                    </div>
                </div>
                <div class="card-body">
                    <div class="tab-content">
                        <div class="table-responsive">
                            <div class="budget-price justify-content-center" *ngIf="listCmds?.length === 0">
                                <h2 class="text-center" style="color: red;">Aucun enlèvement {{statusCmds}} </h2>
                            </div>
                            <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                                *ngIf="listCmds?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>{{'data.company' | translate }}</th>
                                        <th>{{'data.reference' | translate }}</th>
                                        <th>{{'data.creation_date' | translate }}</th>
                                        <!-- <th class="text-center">Ville</th> -->
                                        <th> {{'data.products' | translate }}<br>
                                            {{'data.quantity_product_name' | translate }}
                                        </th>
                                        <th class="text-center">{{'data.total_volume' | translate }}
                                            ({{'data.cubic_meters' | translate }})</th>
                                        <th class="text-center">{{'data.total_weight' | translate }} ({{'data.kg' |
                                            translate }})</th>
                                        <th class="text-center">{{'data.status' | translate }}</th>
                                        <!-- <th class="text-center">Total<br>Commande</th> -->
                                        <!-- <th class="text-center" *ngIf="statusCmds.includes('delivered')">
                                            Date<br>de<br>livraison
                                        </th>
                                        <th class="text-center" *ngIf="statusCmds.includes('paied')">
                                            Date<br>de<br>paiement
                                        </th> -->
                                        <th class="text-center">{{'data.actions' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cmd of listCmds, let index = index">
                                        <td class="text-center text-muted">#{{index+1}}</td>
                                        <td>
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3">
                                                        <div class="widget-content-left">
                                                            <img width="40" class="rounded-circle"
                                                                [src]="cmd?.society?.logo" alt="Logo"
                                                                style="border: 1px solid #000;">
                                                        </div>
                                                    </div>
                                                    <div class="widget-content-left flex2">
                                                        <div class="widget-heading">
                                                            {{cmd?.society?.name}}
                                                        </div>
                                                        <div class="widget-subheading opacity-7">
                                                            {{cmd?.society?.pays}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{{cmd?.ref_facture_cmd}}</td>
                                        <td>
                                            {{cmd?.date_creation_cmd | date:'d MMM y'}}<br>
                                            <b>{{cmd?.date_creation_cmd | date:'HH:mm'}}</b>
                                        </td>
                                        <!-- <td class="text-center">
                                        {{cmd?.user_destination?.adresse_destination}}<br>
                                        <strong>{{cmd?.destination_cmd}}</strong>
                                    </td> -->
                                        <td>
                                            <!-- <div *ngFor="let prd of cmd?.boxes, let index = index">
                                            {{prd?.quantite}} - {{prd?.nom_prod}}
                                        </div> -->
                                            <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                                *ngIf="cmd?.boxes?.length > 0">
                                                <li class="list-group-item"
                                                    *ngFor="let box of cmd?.boxes, let index = index">
                                                    <div class="widget-content p-0">
                                                        <div class="widget-content-wrapper">
                                                            <div class="widget-content-left mr-3">
                                                                <img width="40" height="40"
                                                                    src="https://assets.marketbey.tn/WTM/products/box.png"
                                                                    alt="">
                                                                <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                </ngx-avatar> -->
                                                            </div>
                                                            <div class="widget-content-left">
                                                                <div class="widget-heading">
                                                                    {{box?.quantite}} - {{box?.nom_prod}}
                                                                </div>
                                                                <div class="widget-subheading">
                                                                    {{box?.des_prod}}
                                                                </div>
                                                            </div>
                                                            <div class="widget-content-right">
                                                                <div class="font-size-lg text-muted">
                                                                    <span>{{box?.quantite * box?.volume | number:
                                                                        '0.1'}}
                                                                        {{'data.cubic_meters' | translate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                                *ngIf="cmd?.pallets?.length > 0">
                                                <li class="list-group-item"
                                                    *ngFor="let pallet of cmd?.pallets, let index = index">
                                                    <div class="widget-content p-0">
                                                        <div class="widget-content-wrapper">
                                                            <div class="widget-content-left mr-3">
                                                                <img width="40" height="40"
                                                                    src="https://assets.marketbey.tn/WTM/products/pallet.png"
                                                                    alt="">
                                                                <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                </ngx-avatar> -->
                                                            </div>
                                                            <div class="widget-content-left">
                                                                <div class="widget-heading">
                                                                    {{pallet?.quantity}} - {{pallet?.name_pallet}}
                                                                </div>
                                                                <!-- <div class="widget-subheading">
                                                                {{pallet?.driver_id?.user_transportor?.tel_user}}
                                                            </div> -->
                                                            </div>
                                                            <div class="widget-content-right">
                                                                <div class="font-size-lg text-muted">
                                                                    <span>{{pallet?.quantity * pallet?.volume | number:
                                                                        '0.1'}} {{'data.cubic_meters' | translate
                                                                        }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </td>
                                        <td class="text-center">
                                            {{ cmd?.totalPalletVolume | number:'0.1' }}
                                        </td>
                                        <td class="text-center">
                                            {{ cmd?.totalPoids | number:'0.1' }}
                                        </td>
                                        <td class="text-center">
                                            <div class="badge badge-{{statusCmd[cmd?.etat_cmd].color}}">
                                                {{statusCmd[cmd?.etat_cmd]?.label  | translate }}</div>
                                        </td>
                                        <!-- <td class="text-center">{{cmd?.total_ca_cmd + cmd?.frais_livraison |
                                        number:'0.3'}} EUR</td> -->
                                        <!-- <td *ngIf="statusCmds.includes('delivered')" class="text-center">
                                            {{cmd?.date_relivered_cmd | date:'d MMM
                                            y'}}<br><strong>{{cmd?.date_relivered_cmd |
                                                date:'HH:mm'}}</strong></td>
                                        <td *ngIf="statusCmds.includes('paied')" class="text-center">
                                            {{cmd?.date_payed_cmd | date:'d MMM
                                            y'}}<br><strong>{{cmd?.date_payed_cmd | date:'HH:mm'}}</strong>
                                        </td> -->
                                        <td class="text-center">
                                            <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                                <button class="btn-wide btn btn-info">{{'data.actions' | translate
                                                    }}</button>
                                                <button type="button" ngbDropdownToggle
                                                    class="dropdown-toggle-split dropdown-toggle btn btn-info">
                                                    <span class="sr-only">{{'data.actions' | translate
                                                        }}</span></button>
                                                <div ngbDropdownMenu class="dropdown-menu">
                                                    <!-- <button type="button" *ngIf="cmd?.etat_cmd == 'validated'"
                                                        (click)="validateTakeOff(cmd)" tabindex="0" class="dropdown-item">
                                                        <i class="metismenu-icon pe-7s-check text-danger"
                                                            style="margin-right: 10px;"></i>{{'data.validate_pickup' | translate
                                                        }}
                                                    </button> -->
                                                    <button type="button" (click)="goToNewTab(cmd?._id, '/detail-cmd')"
                                                        tabindex="0" class="dropdown-item">
                                                        <i class="metismenu-icon pe-7s-news-paper"
                                                            style="margin-right: 10px;"></i>{{'data.details_pickup' |
                                                        translate }}
                                                    </button>
                                                    <!-- <button type="button" tabindex="0"
                                                        (click)="goToNewTab(cmd?._id, '/facture')"
                                                        class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                            style="margin-right: 10px;"></i>
                                                        {{'data.bill_pickup' | translate }}
                                                    </button> -->
                                                    <!-- <button type="button" tabindex="0" class="dropdown-item">
                                                        <i class="metismenu-icon pe-7s-print"
                                                            style="margin-right: 10px;"></i>Demande PickUP</button> -->
                                                    <div *ngIf="cmd?.etat_cmd=='validated'">
                                                        <button type="button" tabindex="0" class="dropdown-item"
                                                            (click)="updateStatusCmd(cmd?.qr_code,'canceled')"><i
                                                                class="metismenu-icon pe-7s-close-circle"
                                                                style="margin-right: 10px;"></i>
                                                            {{'data.cancel_pickup' | translate }}
                                                        </button>
                                                    </div>
                                                    <!-- <h6 tabindex="-1" class="dropdown-header">Header</h6> -->
                                                    <!-- <div *ngIf="cmd?.etat_cmd=='validated'">
                                                    <button type="button" tabindex="0"
                                                        class="dropdown-item" (click)="updateStatusCmd(cmd?._id,'canceled')"><i
                                                            class="metismenu-icon pe-7s-close-circle"
                                                            style="margin-right: 10px;"></i>Annuler</button>
                                                </div> -->
                                                    <!-- <div tabindex="-1" class="dropdown-divider"></div> -->
                                                    <!-- <button type="button" tabindex="0"
                                                    class="dropdown-item">Dividers</button> -->
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-block text-center card-footer" *ngIf="listCmds?.length > 0 && pages?.length > 1">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                                        <a class="page-link" tabindex="-1" (click)="previousPage()">
                                            <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                            {{'data.previous' | translate }}</a>
                                    </li>

                                    <div *ngFor="let p of pagesDisplaied">
                                        <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                            <a *ngIf="currentPage!==p" class="page-link"
                                                (click)="selectPage(p)">{{p+1}}</a>

                                            <span *ngIf="currentPage===p" class="page-link">
                                                {{p+1}}
                                                <span class="sr-only">(current)</span>
                                            </span>
                                        </li>
                                    </div>
                                    <!-- <li class="page-item active">
                                <span class="page-link">
                                    2
                                    <span class="sr-only">(current)</span>
                                </span>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li> -->

                                    <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                                        <a class="page-link" tabindex="-1" (click)="nextPage()">{{'data.next' |
                                            translate }} <i class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                                    </li>
                                </ul>
                            </nav>

                            <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                            class="pe-7s-trash btn-icon-wrapper"> </i></button>
                    <button class="btn-wide btn btn-success">Save</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>