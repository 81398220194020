// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// var baseURLAPI = 'http://localhost:3100/';
var baseURLAPI = 'https://bo.cofat.ewassali.tn/api/v0/';

var assetsBaseURL = "https://assets.marketbey.tn/Elloumi/";

export const environment = {
  production: false,

  // API SETTINGS
  apiSavePallet: baseURLAPI + 'settings/save-pallet',
  apiAllPallets: baseURLAPI + 'settings/all-pallets',
  apiDeletePallet: baseURLAPI + 'settings/delete-pallet',

  apiSaveBoxProduct: baseURLAPI + 'settings/save-box-product',
  apiAllBoxesProducts: baseURLAPI + 'settings/all-boxes-products',
  apiDeleteBoxProduct: baseURLAPI + 'settings/delete-box-product',

  // API CALCULATE SIZE PALLETS
  apiCalculatePallets: baseURLAPI + 'size-pallets/calculate',

  // API CMDS
  apiUploadFiles: baseURLAPI + 'cmds/upload-files',

  apiAddCmd: baseURLAPI + 'cmds/add-cmd',
  apiUpdateCmd: baseURLAPI + 'cmds/update-cmd',
  apiExchangeCmd: baseURLAPI + 'cmds/exchange-cmd',
  // apiAddCmds: baseURLAPI + 'cmds/add-cmds',
  apiOneCmd: baseURLAPI + 'cmds/cmd',
  apiAllCmds: baseURLAPI + 'cmds/list-cmds',
  apiAllCmdsByClient: baseURLAPI + 'cmds/list-cmds-client',
  apiAllCmdsByStatusAndClient: baseURLAPI + 'cmds/cmds-status-client',
  apiAllCmdsByStatus: baseURLAPI + 'cmds/cmds-all-status',
  apiStatus: baseURLAPI + 'cmds/liststatus',
  apiSearchCmd: baseURLAPI + 'cmds/search',
  apiFilterCmds: baseURLAPI + 'cmds/filter-cmds',
  apiStatsCmds: baseURLAPI + 'cmds/statistics-cmds',
  apiUpdateCmdStatus: baseURLAPI + 'cmds/updatecmdstatus',
  apiDeleteCmd: baseURLAPI + 'cmds/delete-cmd',
  apiAddComment: baseURLAPI + 'cmds/add-comment',
  apiAllComments: baseURLAPI + 'cmds/all-comments',

  // API CLIENT
  apiAddAccountClient: baseURLAPI + "client/add-account-client",
  apiHasDemandeClient: baseURLAPI + "client/has-demande-client",

  apiGetFraisClient: baseURLAPI + 'client/frais-client',
  apiUsersAuth: baseURLAPI + 'client/auth-client',
  apiInfosUser: baseURLAPI + "client/one-client",
  apiCmdsNotPaiedAndNotReturned: baseURLAPI + "client/details-cmds-client",
  apiAccountsClient: baseURLAPI + "client/accounts-client",

  ////// SOCIETY
  apiAllSocieties: baseURLAPI + 'societies/all-societies',

  // Files and images
  uploadedFilesAssetsBaseURL: assetsBaseURL + "providers/commandes/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
