<!-- <i class="material-icons" style="font-size: 100px;color: #ff0000">info</i> -->
<div mat-dialog-content style="width: 800px;">
    <!-- <h1 style="text-align:center"><strong></strong></h1> -->
    <h2 style="text-align:center;font-size: x-large; color: rgb(170, 32, 32);">Détail de virement :
    </h2>
    <div class="div-form">
        <div class="form-group">
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="data.virement.commandes.length === 0">
                    <h5 class="text-center">Aucun enlèvement créé</h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="data.virement.commandes.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th class="text-left">Référence</th>
                            <th class="text-center">Date Création</th>
                            <th class="text-center">Nom Client<br>&<br>Téléphone</th>
                            <th class="text-center">Ville</th>
                            <th class="text-center">Produits<br>
                                Quantité - Nom produit</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Total<br>d'enlèvement</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of data.virement.commandes, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td class="text">{{cmd.ref_facture_cmd}}</td>
                            <td class="text-center">
                                {{cmd.date_creation_cmd | date:'d MMM y'}}<br>
                                <b>{{cmd.date_creation_cmd | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd.user_destination.nom_destination}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd.user_destination.numtel_destination}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                {{cmd.user_destination.adresse_destination}}<br>
                                <strong>{{cmd.destination_cmd}}</strong>
                            </td>
                            <td>
                                <div *ngFor="let prd of cmd.products, let index = index">
                                    {{prd.quantite}} - {{prd.nom_prod}}
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                    {{statusCmds[cmd.etat_cmd].label | translate}}</div>
                            </td>
                            <td class="text-center">{{cmd.total_ca_cmd + cmd.frais_livraison |
                                number:'0.3'}} DT</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row float-right margin-top">
    <!-- <button style="margin-right: 15px;" [mat-dialog-close]="false" class="btn btn-info">NON</button> -->

    <button style="margin-right: 15px;" [mat-dialog-close]="true" class="btn btn-danger">OUI</button>
</div>